import React, {Component} from 'react';
import {connect} from "react-redux";
import {tokenId, userName, userRole} from '../../utilities/AuthService';
import {withRouter} from 'react-router';
import Sidebar from '../sections/Sidebar'
import * as storyActions from "../../actions/Story";
import ReactToPrint from "react-to-print";



function ToTitleCase(props)
{
    let str = props.name  ;
    return str.replace(/\b\w/g, function (txt) { return txt.toUpperCase(); });
}



class Listentries extends Component {

    constructor(props) {
        super(props);
        this.FilterList=this.FilterList.bind(this)
        this.ReadMore=this.ReadMore.bind(this)
        this.Selection  = this.Selection.bind(this)
        this.state={
            filter: '',
            userrole:userRole().userrole,
            encid:tokenId().id,
        }

    }

    FilterList(e){
        this.setState({filter:e.target.value})
    }



    componentDidMount() {
        let year = '2025';
        this.props.listallentries(this.state.encid,year);

    }


    ReadMore(e,id){
        console.log(id) ;
        window.location = "/readmore?id="+id+'&page=entries';

    }

    Selection(e){
        console.log(e.target.value) ;

    }







    render() {

        var lstories =  []  ;
        console.log(this.props.listentries.Entries.data) ;
        if (this.props.listentries.Entries.data !== undefined ){
            console.log("inside loop");
            console.log(this.props.listentries.Entries.data) ;
            if (this.state.filter !== "" ) {
                lstories = this.props.listentries.Entries.data.filter(
                    item => (item.fname.toLowerCase().search(this.state.filter.toLowerCase()) >= 0 )
                )

            }else{
                lstories =  this.props.listentries.Entries.data  ;

            }
        }


        return (
            <div className="page-content-holder">
                <Sidebar selection="entries" />
                <div className="tab-switching-panel-holder">
                    <div className="tab-switching-panel" id="users" >


                        <div className="intro-text">

                            <h4>Dear {userName().fname} {userName().lname},</h4>
                            <p>Welcome to the online judging of EAF Awards.</p>

                            <p>Thank you for volunteering your precious time to evaluate the work of aspiring journalists.</p>
                            <p>Clicking on the name of the entrant will take you to her/his article.</p>
                            <p>Though the basic criteria of judging will be quality/relevance of content, perspective, original thinking, expression, style and language, your unique eye and sensibility would be what matters the most.</p>
                            <p>Please rate the article based on the criteria given and then click "Submit & Continue Evaluation."</p>
                            <p>You can exit and continue judging whenever you want to. </p>

                            <h4>We request you to complete the judging on or before 24 June 2025.  </h4>
                            <p>Once again, thank you for your valuable contribution to the EAF Awards.</p>
                            <p>If you have questions with the judging process, feel free to write to <a href="mailto:swarupbr@gmail.com">swarupbr@gmail.com</a> or call 9846122376.</p>

                            <h4>2025 topic</h4>
                           {/* <p><strong>How Can We Save Kerala's Monsoon Dream-Season From Becoming A Recurring Nightmare?</strong></p>
                            <p>Every media vehicle has made its recommendations - visionary thinking, people-centric long-term planning and implementation, political will, integrating environment sustainability into everything - the list is endless. Kerala definitely needs a new sustainable model of development - and the answer perhaps lies in the middle ground between the two distinctly diverse schools of thought developing in this space. How deep can you dig? How high can you fly? How many planned conversations can you have? How interesting a perspective can you shed?</p>*/}

                            <p><strong>Welcome to the 2025 edition of the E A Fernandez Award for aspiring journalists. </strong></p>
                            <p>We invite you to study the socio-economic transformation of a village in Kerala through tourism.</p>
                            <p><strong><u>Poovar: Paradise Found? Paradise Lost?</u></strong></p>
                            <br/>
                            <p><strong>Project Objective:</strong></p>
                            <br/>
                            <p>Your multimedia report should delve into the complex relationship between tourism and the local
                                economy in Poovar, Kerala. Poovar, once a quiet fishing village, has undergone a dramatic
                                transformation due to its integration into the global tourism network. This project aims to
                                investigate the multifaceted impacts of this change, focusing on the lived experiences of the local community, particularly those who have transitioned away from their traditional livelihoods.</p>
                            <p>Key Research Questions:</p>
                            <p>Economic Impacts:</p>
                            <ul>
                                <li>How has tourism affected income levels and income distribution within the Poovar
                                    community?</li>
                                <li>What new employment opportunities has tourism created, and how have these impacted
                                    traditional occupations like fishing and agriculture?</li>
                                <li>Has tourism led to the development of new industries and businesses in Poovar?</li>
                                <li>How has the cost of living in Poovar changed due to tourism?</li>
                            </ul>

                            <p>Social Impacts:</p>
                            <ul>
                                <li> How has tourism influenced the social fabric and cultural practices of Poovar?</li>
                                <li> Has tourism led to changes in community dynamics and relationships?</li>
                                <li> What are the perceptions and attitudes of the local community towards tourism development?</li>
                                <li> How has tourism affected the quality of life in Poovar, considering factors like infrastructure, environment, and access to resources?</li>
                            </ul>
                           <p>Environmental Impacts:</p>
                            <ul>
                                <li> What are the environmental consequences of tourism development in Poovar, particularly on the fragile backwater ecosystem?</li>
                                <li> How has tourism impacted waste management, water quality, and biodiversity in the
                                region?</li>
                                <li> Are there sustainable tourism practices in place, and how effective are they?</li>
                            </ul>
                            <p>Methodology:</p>
                            <ul>
                                <li>Field Study: Conduct in-depth interviews with a diverse range of Poovar residents,
                                    including:
                                    <ul>
                                        <li> Fishermen and farmers who have shifted to tourism-related jobs</li>
                                        <li> Business owners (hotels, restaurants, boat operators, souvenir shops)</li>
                                        <li> Community leaders and local officials</li>
                                        <li> Women and youth</li>
                                        <li> Long-term residents and newcomers</li>
                                    </ul>
                                </li>
                                <li> Data Collection: Gather quantitative data on income levels, employment, business growth, and environmental indicators. </li>
                                <li> Visual Storytelling: Capture the essence of Poovar through compelling photographs, videos, and audio recordings. </li>
                                <li> Multimedia Report: Create an engaging and informative report using a combination of text, images, videos, and interactive elements.</li>
                            </ul>
                            <p>Timeline:</p>
                            <ul>
                                <li> March: Background research, field study in Poovar, including interviews, data collection, and multimedia documentation.</li>
                                <li> April: Data analysis, report writing, and submission of the multimedia report on or before 9th May 2025.</li>
                            </ul>

                            <p>Expected Outcomes:</p>
                            <ul>
                                <li> A deeper understanding of the transformative power of tourism in a rural community.</li>
                                <li> Insights into the challenges and opportunities associated with sustainable tourism development.</li>
                                <li> A compelling multimedia report that raises awareness and informs policy discussions.</li>
                            </ul>
                            <p>Additional Possibilities:</p>
                            <ul>
                                <li> You can explore the role of government policies and initiatives in promoting tourism and mitigating its negative impacts.</li>
                                <li> The report can include comparisons with other tourism destinations in Kerala or India.</li>
                                <li> You can investigate the potential for community-based tourism and alternative livelihood options in Poovar.</li>
                                <li> The project can also be expanded to include an advocacy component, proposing solutions for sustainable tourism development in Poovar.</li>
                            </ul>
                            <p>In short, the project should contribute to a better understanding of the complex relationship between tourism and local communities in Kerala.</p>
                            <p>We sincerely hope you have a rewarding experience doing this slow-journalism project. We look forward to reading your story on Poovar.   </p>
                            <br/>
                            <p><strong>മാധ്യമ മേഖലയിൽ വലിയ സംഭാവനകൾ നൽകാൻ ആഗ്രഹിക്കുന്ന യുവ ജേർണലിസ്റ്റുകൾക്ക് ഈ എ ഫെർണാണ്ടസ് അവാർഡ് 2025 ലേക്ക് സ്വാഗതം </strong></p>
                            <p>ടൂറിസത്തിലൂടെ പൂവാർ എന്ന  കേരള ഗ്രാമത്തിനുണ്ടായ സാമൂഹിക-സാമ്പത്തിക പരിവർത്തനങ്ങൾ  അപഗ്രഥിക്കാൻ ഞങ്ങൾ നിങ്ങളെ ക്ഷണിക്കുന്നു  </p>

                            <p>പൂവാർ: സ്വർഗ്ഗത്തിന്റെ സൃഷ്ടിയോ നഷ്ടമായ സ്വർഗ്ഗമോ </p>
                            <br/>
                            <p>പഠനത്തിന്റെ ലക്‌ഷ്യം </p>
                            <br/>
                            <p>വിനോദസഞ്ചാര മേഖലയും പൂവാറിലെ ഗ്രാമീണ സാമ്പത്തിക അവസ്ഥയും തമ്മിലുള്ള സങ്കീർണമായ ബന്ധത്തെ സംബന്ധിച്ച് ഒരു മൾട്ടിമീഡിയ റിപ്പോർട്ടാണ് തയ്യാറാക്കേണ്ടത്.</p>
                            <p>ആഗോള ടൂറിസം നെറ്റ് വർക്കിലേക്ക് ഉൾച്ചേർന്നതോടെ നാടകീയമായ മാറ്റമാണ് ഈ ചെറിയ ഗ്രാമത്തിൽ ഉണ്ടായത്. ഈ മാറ്റം അവിടെ ജീവിക്കുകയും തൊഴിലെടുക്കുകയും ചെയ്യുന്ന  സാധാരണ മനുഷ്യരുടെ ജീവിതത്തിൽ വരുത്തിയ മാറ്റത്തിന്റെ സമഗ്രമായ പഠനമാണ് നടത്തേണ്ടത്. പ്രത്യേകിച്ച് പരമ്പരാഗത തൊഴിൽ മേഖലയിൽ വന്ന മാറ്റങ്ങൾ.</p>
                            <p>പ്രധാന ഗവേഷണ ചോദ്യങ്ങൾ </p>
                            <br/>
                            <p>സാമ്പത്തിക സ്വാധീനം </p>
                            <ul>
                                <li> വരുമാന സൃഷ്ടി, വരുമാന വിതരണം എന്നിവയിലുണ്ടായ മാറ്റങ്ങൾ</li>
                                <li> പുതിയതായുണ്ടായ തൊഴിൽ അവസരങ്ങളും മത്സ്യബന്ധനം, കൃഷി തുടങ്ങിയ പരമ്പരാഗത തൊഴിൽ മേഖലയിൽ ഉണ്ടായ പ്രത്യാഘാതവും</li>
                                <li> ടൂറിസത്തിലൂടെ പുതിയ വ്യവസായങ്ങളോ ബിസിനസോ പൂവാറിൽ ഉണ്ടായിട്ടുണ്ടോ</li>
                                <li> ജീവിത ചിലവിൽ ഉണ്ടായ മാറ്റം</li>
                            </ul>
                            <p>സാമൂഹിക പ്രത്യാഘാതങ്ങൾ </p>
                            <ul>
                                <li> സാമൂഹിക സാംസ്കാരിക മേഖലകളിൽ ഉണ്ടായ മാറ്റം</li>
                                <li> സാമൂഹിക ബന്ധങ്ങളിൽ ഉണ്ടായ മാറ്റം</li>
                                <li> ജനങ്ങൾക്ക് ടൂറിസത്തോടുള്ള മനോഭാവം</li>
                                <li> ജീവിത ഗുണനിലവാരത്തിലുണ്ടായ മാറ്റം: അടിസ്ഥാന സൗകര്യങ്ങൾ, വിഭവ ലഭ്യത</li>
                            </ul>
                            <p>പരിസ്ഥിതിയിലുണ്ടാക്കിയ മാറ്റം </p>
                            <ul>
                                <li> പൂവാറിന്റെ പരിസ്ഥിതിയിൽ പ്രത്യേകിച്ച് കായൽ അടക്കമുള്ള ജലാശയങ്ങളിൽ വന്ന മാറ്റം</li>
                                <li> മാലിന്യ നിർമാർജനം, ജലത്തിന്റെ ഗുണനിലവാരം, ജൈവ വൈവിധ്യം എന്നിവയിൽ വന്ന മാറ്റങ്ങൾ</li>
                                <li> സുസ്ഥിര വിനോദസഞ്ചാര മാതൃകകൾ അവിടെയുണ്ടോ, ഉണ്ടെങ്കിൽ അവയുടെ സ്വാധീനം</li>
                            </ul>
                            <p>പഠന രീതി </p>
                            <ul>
                                <li>ഫീൽഡ് സ്റ്റഡി: പൂവാറിൽ ജീവിക്കുന്ന വിവിധ വിഭാഗങ്ങളിലുള്ള മനുഷ്യരുമായി വിശദമായ സംഭാഷണങ്ങൾ,
                                <ul>
                                    <li> ടൂറിസം മേഖലയിലേക്ക് മാറിയ കർഷകർ, മത്സ്യ തൊഴിലാളികൾ</li>
                                    <li>ബിസിനസ് സംരംഭകർ ( ഹോട്ടലുകൾ, റസ്റ്റോറന്റുകൾ, ബോട്ട് ഓപ്പറേറ്റേഴ്‌സ്, സോവനീർ കടകൾ.)</li>
                                    <li> സാമൂഹിക രാഷ്ട്രീയ നേതാക്കൾ, ഉദ്യോഗസ്ഥർ</li>
                                    <li>സ്ത്രീകൾ, കുട്ടികൾ</li>
                                    <li>സ്ഥിരമായി അവിടെ താമസിക്കുന്നവരും ഇപ്പോൾ അവിടെ വന്ന് ജീവിക്കുന്നവരും </li>
                                </ul>
                                </li>
                                <li>ഡാറ്റ ശേഖരണം</li>
                                <li>വരുമാനം, തൊഴിൽ ലഭ്യത, ബിസിനസ് വളർച്ച, പാരിസ്ഥിതിക മാറ്റങ്ങൾ എന്നിവയെക്കുറിച്ചുള്ള കൃത്യമായ കണക്കുകൾ</li>
                                <li>ദൃശ്യ ചിത്രീകരണം: പൂവാറിന്റെ മാറ്റം തിരിച്ചറിയാൻ സഹായിക്കുന്ന വീഡിയോസ്, ഫോട്ടോഗ്രാഫ്സ്, അഭിമുഖങ്ങൾ എന്നിവ</li>
                                <li>എഴുത്തും ദൃശ്യങ്ങളും മറ്റ് മൾട്ടീമീഡിയ സംവിധാനങ്ങളും ഉപയോഗിച്ചുള്ള അപഗ്രഥനം</li>
                            </ul>
                            <p>ടൈം ഫ്രെയിം </p>
                            <ul>
                                <li>മാർച്ച്:   പശ്ചാത്തല ഗവേഷണം,  പൂവാറിൽ നടത്തുന്ന ഫീൽഡ് സ്റ്റഡി, അഭിമുഖങ്ങൾ, ഡേറ്റ ശേഖരണം, മൾട്ടീമീഡിയ ഡോക്യൂമെന്റേഷൻ</li>
                                <li>ഏപ്രിൽ: ഡേറ്റ അനാലിസിസ്, റിപ്പോർട്ട് തയ്യാറാക്കൽ, മൾട്ടീമീഡിയ റിപ്പോർട്ട് അവതരിപ്പിക്കൽ (9 മെയ്, 2025 ന് മുൻപ്)</li>
                            </ul>
                           <p>പ്രതീക്ഷിക്കുന്ന ഫലം:</p>
                            <ul>
                                <li> ഒരു ഗ്രാമീണ സാമ്പത്തിക വ്യവസ്ഥയിൽ ടൂറിസം സൃഷ്ടിക്കുന്ന മാറ്റത്തെക്കുറിച്ചുള്ള ആഴത്തിലുള്ള അറിവ്</li>
                                <li> സുസ്ഥിരമായ മാറ്റങ്ങൾക്കായി വേണ്ടിവരുന്ന വെല്ലുവിളികളും സാധ്യതകളും</li>
                                <li> നയരൂപീകരണത്തിന് സഹായിക്കും വിധം അവബോധം സൃഷ്ടിക്കുന്ന മൾട്ടി മീഡിയ റിപ്പോർട്ട്</li>
                            </ul>
                            <p>മറ്റ് സാദ്ധ്യതകൾ </p>
                            <ul>
                                <li>സുസ്ഥിരമായ ടൂറിസം വികസനത്തിനും പ്രതികൂല സാഹചര്യങ്ങൾ മറികടക്കുന്നതിനും  സർക്കാർ സ്വീകരിക്കേണ്ട നയസമീപനം എന്താവണം എന്ന് നിങ്ങൾക്ക്  പരിശോധിക്കാവുന്നതാണ്</li>
                                <li> കേരളത്തിലെയോ ഇന്ത്യയിലെയോ മറ്റ് വിനോദ സഞ്ചാര കേന്ദ്രങ്ങളുമായി താരതമ്യം ചെയ്യാവുന്നതാണ്</li>
                                <li> പൂവാറിൽ കമ്മ്യൂണിറ്റി ബേസ്‌ഡ് ടൂറിസത്തിന്റെ സാധ്യതകളും ബദൽ തൊഴിൽ സാധ്യതകളും (Alternative Livelihood) നിങ്ങൾക്ക് പരിശോധിക്കാവുന്നതാണ്</li>
                                <li> പ്രോജക്ടിനെ സുസ്ഥിര വികസനത്തിന് സഹായകമായ ഒരു അഡ്വക്കസി ഡോക്യുമെന്റ് ആക്കാവുന്നതാണ്  </li>
                            </ul>
                            <p>ടൂറിസവും പ്രാദേശിക സമൂഹവുമായുള്ള സങ്കീർണമായ ബന്ധത്തെക്കുറിച്ച്‌ ഈ പഠനത്തിലൂടെ കൂടുതൽ വ്യക്തത കൈവരേണ്ടതാണ്</p>
                            <p>സ്ലോ ജേർണലിസത്തിന്റെ ഭാഗമായ ഈ പ്രോജക്റ്റ് നിങ്ങൾക്ക് വ്യത്യസ്തമായ ഒരു അനുഭവമാകും എന്ന് ഞങ്ങൾ കരുതുന്നു.  പൂവാറിനെക്കുറിച്ചുള്ള നിങ്ങളുടെ അവതരണത്തിനായി  ഞങ്ങൾ പ്രതീക്ഷയോടെ കാത്തിരിക്കുന്നു.</p>

                        </div>

                        <div className="panel-options-head entries">
                            <h5>Entries</h5>
                            <ReactToPrint
                                trigger={() => <a className="btn print-btn icon-btn icon-btn--print" href="#">Print Entries</a>}
                                content={() => this.componentRef}
                            />
                        </div>
                        <div className="panel-options-content" >
                            <div className="scrollable table-wrapper">
                                <table className="user-table table-in"  ref={el => (this.componentRef = el)}>
                                    <thead>
                                    <tr>

                                        <th>Name</th>

                                        {/*<th>Score</th>*/}
                                        <th>Marks</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    { lstories.map((list,index) =>

<tr  key={`list-shortlisted-table-data-`+index}  className="show-click" onClick={(e) => this.ReadMore(e,list.storyid)}>


                                            <td>

                                                <label className="sl-no">{index+1}</label>
                                                <label className="inline-text">{list.fname} {list.lname}</label>
                                            </td>

                                            {/*<td>{list.remarks}</td>*/}
                                            <td>{list.marks}</td>

                                        </tr>
                                    )}
                                    </tbody>





                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );

    }
}


const mapStateToProps = state => ({
    listentries:state.StoryReducer.ListEntries,


});



export default  withRouter(connect(mapStateToProps, {
    listallentries:storyActions.listAllEntries
})(Listentries));